import { render, staticRenderFns } from "./fence.vue?vue&type=template&id=2922c462&"
import script from "./fence.vue?vue&type=script&lang=js&"
export * from "./fence.vue?vue&type=script&lang=js&"
import style0 from "./fence.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2922c462')) {
      api.createRecord('2922c462', component.options)
    } else {
      api.reload('2922c462', component.options)
    }
    module.hot.accept("./fence.vue?vue&type=template&id=2922c462&", function () {
      api.rerender('2922c462', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/jt808/vehicleAlarmSetting/modules/fence.vue"
export default component.exports