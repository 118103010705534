var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "40%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          staticClass: "area-manager",
          attrs: { model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "开关", prop: "onOff" } },
            [
              _c("a-switch", {
                attrs: {
                  disabled: _vm.readOnly,
                  "checked-children": "开",
                  "un-checked-children": "关",
                },
                model: {
                  value: _vm.form.onOff,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "onOff", $$v)
                  },
                  expression: "form.onOff",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "防抖延时(秒)", prop: "antiShake" } },
            [
              _c("a-input-number", {
                attrs: {
                  disabled: _vm.readOnly,
                  precision: 0,
                  min: 0,
                  placeholder: "请输入防抖延时",
                },
                model: {
                  value: _vm.form.antiShake,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "antiShake", $$v)
                  },
                  expression: "form.antiShake",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "路段范围", prop: "isDraw" } },
            [
              _c("a-button", { on: { click: _vm.handleMapDraw } }, [
                _vm._v("在地图中绘制"),
              ]),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "车辆" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.readOnly,
                  replaceFields: _vm.replaceFields,
                  "show-search": "",
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  placeholder: "模糊搜索，可搜索自编号或车牌号",
                  "allow-clear": "",
                  "tree-default-expand-all": "",
                  "tree-data": _vm.vehicleTreeData,
                  "tree-checkable": "",
                  "show-checked-strategy": _vm.SHOW_PARENT,
                  treeNodeFilterProp: "title",
                },
                model: {
                  value: _vm.deviceIds,
                  callback: function ($$v) {
                    _vm.deviceIds = $$v
                  },
                  expression: "deviceIds",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注" } },
            [
              _c("a-textarea", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入备注",
                  "auto-size": { minRows: 3 },
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  !_vm.readOnly
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" " + _vm._s(_vm.readOnly ? "返回" : "取消") + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("road-scope-map", {
        ref: "roadScopeMapRef",
        attrs: { position: { top: "4%", left: "52%" } },
        on: { ok: _vm.handleOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }